<template>
	<div>
		<ddcard class="box-card" :title="$t('dashboard.left.stats-prods-header')" v-loading="isLoading">
			<div slot="title">
				<svg-icon icon-class="a" class="dashboard-icon-card" />
				<span class="dd-title">{{ $t('dashboard.left.stats-prods-header') }}</span>
			</div>
			<el-row :gutter="20">
				<el-col :xs="24" :sm="16" :md="14" :lg="16" :xl="18">
					<router-link to="/rapoarte/produse" class="redirect-chart">
						<el-button>{{$t('dashboard.left.stats-more')}}</el-button>
					</router-link>
					<line-chart class="orders-chart" :options="chartOptions" :chartData="reactiveChart"></line-chart>
				</el-col>
				<el-col :xs="24" :sm="8" :md="10" :lg="8" :xl="6" class="stats-col">
					<products-compound></products-compound>
				</el-col>
			</el-row>

		</ddcard>
	</div>
</template>

<script>
	const ddcard = () => import('@/components/DDCard');
	const lineChart = () =>
		import('@/components/Charts/line');
	const ProductsCompound = () => import('./ProductsCompound');

	import {
		mapState,
		mapGetters
	} from 'vuex';
	export default {
		components: {
			ddcard,
			ProductsCompound,
			'line-chart': lineChart,
		},
		data() {
			return {
				list: null,
				chartOptions: {
					height: '500px',
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								stepSize: 1
							}
						}]
					}
				}
			};
		},
		computed: {
			...mapState({
				isLoading: (state) => state.dashboard.compound.loading,
				loaded: (state) => state.dashboard.compound.loaded,
			}),
			...mapGetters({
				productsChartDays: 'dashboard/productsChartDays',
				productsChartViews: 'dashboard/productsChartViews',
				productsChartFavorites: 'dashboard/productsChartFavorites',
				productsChartCart: 'dashboard/productsChartCart'
			}),
			reactiveChart() {
				const chart = {
					labels: this.productsChartDays,
					datasets: [{
						label: this.$t('dashboard.left.stats-prods-views'),
						data: this.productsChartViews,
						backgroundColor: [
							'rgb(76, 175, 80, 0.4)',
						],
						borderColor: [
							'rgb(76, 175, 80)',
						],
						borderWidth: 1
					}, {
						label: this.$t('dashboard.left.stats-prods-favs'),
						data: this.productsChartFavorites,
						backgroundColor: [
							'rgb(244, 81, 108, 0.4)',
						],
						borderColor: [
							'rgb(244, 81, 108)',
						],
						borderWidth: 1
					}, {
						label: this.$t('dashboard.left.stats-prods-basket'),
						data: this.productsChartCart,
						backgroundColor: [
							'rgb(54, 163, 247, 0.4)',
						],
						borderColor: [
							'rgb(54, 163, 247)',
						],
						borderWidth: 1
					}]
				};

				return chart;
			}
		},
		methods: {

		}
	};
</script>